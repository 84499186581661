var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"card mb-7 mb-mb-0"},[_vm._m(0),_c('div',{staticClass:"card-body pt-3 pb-0"},[_c('div',{staticClass:"position-relative min-h-300px"},[(_vm.loader)?_c('px-loader',{attrs:{"is-full-window":false}}):_c('px-new-table',{attrs:{"header":_vm.header,"items":_vm.payments,"paginate":false,"has-wrapper":false},scopedSlots:_vm._u([{key:"cash",fn:function(ref){
var item = ref.item;
return [_c('i',{class:[
              'fa fa-genderless fs-2',
              { 'text-danger': !item.attributes.cash },
              { 'text-success': item.attributes.cash } ]})]}},{key:"project",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(item.attributes.proposal_scope.data.attributes.subject.data.attributes.address_line_1)+" ")]}},{key:"city",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(item.attributes.proposal_scope.data.attributes.subject.data.attributes.city)+" ")]}},{key:"date",fn:function(ref){
            var item = ref.item;
return [(_vm.isCurrentYear(item.attributes.date))?[_vm._v(" "+_vm._s(_vm._f("moment")(item.attributes.date,'MMM D'))+" ")]:[_vm._v(" "+_vm._s(_vm._f("moment")(item.attributes.date,'MMM D, YYYY'))+" ")]]}},{key:"amount",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$n(item.attributes.amount, 'currency', 'en-CA'))+" ")]}}])})],1)])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"card-header align-items-center py-2"},[_c('h4',{staticClass:"card-title fw-bold"},[_vm._v("Recent Payments")])])}]

export { render, staticRenderFns }