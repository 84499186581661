<template>
  <div class="card weather-widget position-relative min-h-300px">
    <px-loader v-if="isUpdating" :is-full-window="false" bg="blue" />

    <div v-if="!isUpdating" class="card-header align-items-center border-0 small py-4">
      <div v-if="city && country" class="d-flex align-items-center">
        <i class="bi bi-geo-alt me-2" />
        {{ city }}, {{ country }}
      </div>

      <div class="card-toolbar">
        <px-btn size="sm" color="light" extended-classes="btn-icon" @click.native="update">
          <span class="svg-icon svg-icon-muted svg-icon-2hx mb-0">
            <inline-svg src="/assets/media/icons/duotune/arrows/arr029.svg" />
          </span>
        </px-btn>
      </div>
    </div>
    <div v-if="!isUpdating" class="card-body pt-0">
      <px-weather-current-day
        v-if="currentDayForecast"
        :temp="currentDayForecast.temp"
        :condition="currentDayForecast.conditions"
        :icon="currentDayForecast.icon"
        :wind-speed="currentDayForecast.wspd"
        :wind-direction="currentDayForecast.wdir"
        :humidity="currentDayForecast.humidity"
        class="mb-6"
      />

      <hr class="opacity-10 my-6" />

      <div v-if="fiveDaysForecast && fiveDaysForecast.length" class="row">
        <div v-for="(forecastDay, index) in fiveDaysForecast" :key="index" class="col mb-4 mb-md-0">
          <px-weather-forecast-day :day="forecastDay.datetimeStr" :icon="forecastDay.icon" :temp="forecastDay.temp" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import PxWeatherCurrentDay from '@/components/elements/weather-widget/PxWeatherCurrentDay';
import PxWeatherForecastDay from '@/components/elements/weather-widget/PxWeatherForecastDay';

export default {
  components: {
    PxWeatherCurrentDay,
    PxWeatherForecastDay,
  },
  computed: {
    ...mapGetters({
      city: 'weather/city',
      country: 'weather/country',
      currentDayForecast: 'weather/currentDayForecast',
      fiveDaysForecast: 'weather/fiveDaysForecast',
    }),
  },
  data() {
    return {
      isUpdating: true,
    };
  },
  async mounted() {
    await this.actionInitOverallWeather();
    await this.actionCurrentDayForecastWeather();
    await this.actionFiveDaysForecastWeather();

    this.isUpdating = false;
  },
  methods: {
    ...mapActions({
      actionInitOverallWeather: 'weather/initOverall',
      actionUpdateOverallWeather: 'weather/updateOverall',
      actionCurrentDayForecastWeather: 'weather/currentDayForecast',
      actionFiveDaysForecastWeather: 'weather/fiveDaysForecast',
    }),

    async update() {
      this.isUpdating = true;

      await this.actionUpdateOverallWeather();

      this.isUpdating = false;
    },
  },
};
</script>
