<template>
  <div>
    <px-toolbar title="Dashboard" />
    <!--begin::Container-->
    <div class="container-xxl pb-10">
      <!--begin::Content-->
      <div class="content flex-row-fluid">
        <!--begin::Row-->
        <div class="row">
          <!--begin::Col-->
          <div class="col-md-6 col-xl-8">
            <pxs-recent-proposals :current-manager-id="user.id" :archive="false" />

            <pxs-recent-payments v-if="hasExtendedRoles" :current-manager-id="user.id" />
          </div>
          <!--end::Col-->

          <!--begin::Col-->
          <div class="col-md-6 col-xl-4">
            <!--begin::Card-->
            <div v-if="statistics && hasExtendedRoles" class="card mb-7">
              <!--begin::Header-->
              <div class="card-header align-items-center py-4">
                <h4 class="card-title fw-bold">Active Project Sales</h4>
              </div>
              <!--end::Header-->
              <!--begin::Body-->
              <div class="card-body">
                <div class="d-flex flex-wrap align-items-center mb-5">
                  <div class="d-flex flex-center me-5 me-sm-9 mb-5">
                    <div class="position-relative h-150px w-150px">
                      <div class="position-relative top-50 start-50 translate-middle">
                        <px-chart-doughnut
                          v-if="!isLoading"
                          id="kt_star_chart"
                          :data="chartData"
                          :labels="chartLabels"
                          :background-colors="chartBackgroundColors"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="mb-5">
                    <div class="fs-2 fw-bold">{{ $n(statistics.total, 'currency', 'en-CA') }}</div>
                    <div class="text-uppercase text-ls text-muted fs-9">Current Signed</div>
                  </div>
                </div>
                <!--begin::Labels-->
                <div class="d-flex flex-column justify-content-center flex-row-fluid mb-5">
                  <!--begin::Label-->
                  <div class="d-flex fs-7 text-gray-800 align-items-center mb-3">
                    <div class="bullet bg-landsscaping me-3" />
                    <div>Landscaping</div>
                    <div class="ms-auto fw-bold">
                      {{ $n(statistics.landscaping, 'currency', 'en-CA') }}
                    </div>
                  </div>
                  <!--end::Label-->
                  <!--begin::Label-->
                  <div class="d-flex fs-7 text-gray-800 align-items-center mb-3">
                    <div class="bullet bg-pool me-3" />
                    <div>Pool</div>
                    <div class="ms-auto fw-bold">
                      {{ $n(statistics.pool, 'currency', 'en-CA') }}
                    </div>
                  </div>
                  <!--end::Label-->
                  <!--begin::Label-->
                  <div class="d-flex fs-7 text-gray-800 align-items-center mb-3">
                    <div class="bullet bg-woodwork me-3" />
                    <div>Woodwork</div>
                    <div class="ms-auto fw-bold">
                      {{ $n(statistics.woodwork, 'currency', 'en-CA') }}
                    </div>
                  </div>
                  <!--end::Label-->
                  <!--begin::Label-->
                  <div class="d-flex fs-7 text-gray-800 align-items-center">
                    <div class="bullet bg-info me-3" />
                    <div>Change Orders</div>
                    <div class="ms-auto fw-bold">
                      {{ $n(statistics.change_order, 'currency', 'en-CA') }}
                    </div>
                  </div>
                  <!--end::Label-->
                </div>
                <!--end::Labels-->
              </div>
              <!--end: Card Body-->
            </div>
            <!--end::Card-->

            <px-weather-widget />
          </div>
          <!--end::Col-->
        </div>
        <!--end::Row-->
      </div>
      <!--end::Content-->
    </div>
    <!--end::Container-->
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import PxChartDoughnut from '@/components/elements/PxChartDoughnut';
import PxWeatherWidget from '@/components/elements/weather-widget/PxWeatherWidget';

import PxsRecentProposals from '@/sections/PxsRecentProposals';
import PxsRecentPayments from '@/sections/PxsRecentPayments';

export default {
  components: {
    PxChartDoughnut,
    PxsRecentProposals,
    PxsRecentPayments,
    PxWeatherWidget,
  },
  data() {
    return {
      isLoading: false,
      chartData: [],
      chartLabels: [],
      chartBackgroundColors: [],
    };
  },
  computed: {
    ...mapGetters({
      user: 'auth/user',
      statistics: 'dashboardStatistics/statistics',
    }),
  },
  async mounted() {
    const params = {};
    if (!this.isMaster) params.manager = this.user.id;
    if (this.managerId) params.manager = this.managerId;

    this.isLoading = true;

    await this.actionInitOverallStatistics(params);

    this.setChartData();

    this.isLoading = false;
  },
  methods: {
    ...mapActions({
      actionInitOverallStatistics: 'dashboardStatistics/initOverall',
    }),
    setChartData() {
      if (this.statistics.landscaping) {
        this.chartData.push(this.statistics.landscaping);
        this.chartLabels.push('Landscaping');
        this.chartBackgroundColors.push('#50cd89');
      }
      if (this.statistics.pool) {
        this.chartData.push(this.statistics.pool);
        this.chartLabels.push('Pool');
        this.chartBackgroundColors.push('#00a3ff');
      }
      if (this.statistics.woodwork) {
        this.chartData.push(this.statistics.woodwork);
        this.chartLabels.push('Woodworking');
        this.chartBackgroundColors.push('#cc9651');
      }
      if (this.statistics.change_order) {
        this.chartData.push(this.statistics.change_order);
        this.chartLabels.push('Change Order');
        this.chartBackgroundColors.push('#00a3ff');
      }
    },
  },
};
</script>
