<template>
  <div class="card mb-7">
    <!--begin::Header-->
    <div class="card-header align-items-center py-2">
      <h4 class="card-title fw-bold">Recent Proposals</h4>
      <div class="card-toolbar">
        <router-link
          :to="{ name: 'create-proposal' }"
          class="btn btn-sm btn-light btn-active-primary d-flex align-items-center"
        >
          <span class="svg-icon svg-icon-3">
            <inline-svg src="/assets/media/icons/duotune/arrows/arr075.svg" />
          </span>
          New Proposal
        </router-link>
      </div>
    </div>
    <!--end::Header-->

    <!--begin::Body-->
    <div class="card-body py-3">
      <div class="position-relative">
        <px-loader v-if="loader" :is-full-window="false" />
        <px-new-table :header="header" @filtered="filtered" :items="items" :paginate="false" :has-wrapper="false">
          <template slot="scope_type" slot-scope="{ item }">
            <div class="text-body">
              <div class="symbol symbol-35px symbol-circle">
                <span
                  :class="[
                    'symbol-label text-white fw-semi-bold',
                    { 'bg-primary': item.attributes.scope_type === 'Swimming Pool' },
                    { 'bg-success': item.attributes.scope_type === 'Landscaping' },
                    { 'bg-warning': item.attributes.scope_type === 'Woodworking' },
                    { 'bg-dark': !item.attributes.scope_type },
                  ]"
                  v-tooltip="{
                    content: item.attributes.scope_type,
                    delay: { show: 400, hide: 0 },
                  }"
                >
                  {{ typeFirstLetter(item.attributes.scope_type) }}
                </span>
              </div>
            </div>
          </template>
          <template slot="address" slot-scope="{ item }">
            <router-link
              class="text-body text-hover-primary"
              :to="{ name: 'proposal-preview', params: { id: item.id } }"
            >
              {{ item.attributes.subject.data.attributes.address_line_1 }}
            </router-link>
          </template>
          <template slot="city" slot-scope="{ item }">
            {{ item.attributes.subject.data.attributes.city }}
          </template>
          <template slot="version" slot-scope="{ item }">
            <span class="badge badge-light fw-semi-bold fs-10">v{{ item.attributes.version || '1' }}</span>
          </template>
          <template slot="created_at" slot-scope="{ item }">
            <span class="text-body d-block">
              <template v-if="isCurrentYear(item.attributes.created_at)">
                {{ item.attributes.created_at | moment('MMM D') }}
              </template>
              <template v-else>
                {{ item.attributes.created_at | moment('MMM D, YYYY') }}
              </template>
            </span>
          </template>
          <template slot="updated_at" slot-scope="{ item }">
            <span class="text-body d-block">
              <template v-if="isCurrentYear(item.attributes.updated_at)">
                {{ item.attributes.updated_at | moment('MMM D') }}
              </template>
              <template v-else>
                {{ item.attributes.updated_at | moment('MMM D, YYYY') }}
              </template>
            </span>
            <span v-if="compareDates(item.attributes.created_at, item.attributes.updated_at)" class="text-body d-block">
              {{ item.attributes.updated_at | moment('LTS') }}
            </span>
          </template>
          <template slot="team" slot-scope="{ item }">
            <div class="symbol-group flex-nowrap">
              <span
                class="symbol symbol-35px symbol-circle me-4"
                v-tooltip="{
                  content: `${item.attributes.subject.data.attributes.manager.data.attributes.first_name} ${item.attributes.subject.data.attributes.manager.data.attributes.last_name}`,
                  delay: { show: 400, hide: 0 },
                }"
              >
                <img
                  v-if="item.attributes.subject.data.attributes.manager.data.attributes.avatar"
                  :src="item.attributes.subject.data.attributes.manager.data.attributes.avatar"
                  :alt="`${item.attributes.subject.data.attributes.manager.data.attributes.first_name} ${item.attributes.subject.data.attributes.manager.data.attributes.last_name}`"
                />
                <span v-else class="symbol-label bg-light-primary text-primary fw-semi-bold">
                  {{ item.attributes.subject.data.attributes.manager.data.attributes.first_name.charAt(0)
                  }}{{ item.attributes.subject.data.attributes.manager.data.attributes.last_name.charAt(0) }}
                </span>
              </span>
              <template v-if="item.attributes.users_array.length">
                <span
                  v-for="(user, index) in item.attributes.users_array"
                  :key="index"
                  class="symbol symbol-35px symbol-circle"
                  v-tooltip="{
                    content: user[0],
                    delay: { show: 400, hide: 0 },
                  }"
                >
                  <img v-if="user[1]" :src="user[1]" :alt="user[0]" />
                  <span v-else class="symbol-label bg-light-primary text-primary fw-semi-bold">
                    {{ firstLettersOfEachWord(user[0]) }}
                  </span>
                </span>
              </template>
            </div>
          </template>
          <template class="text-end" slot="value" slot-scope="{ item }">
            <span class="text-body d-block">
              {{ $n(item.attributes.sub_total, 'currency', 'en-CA') }}
            </span>
          </template>
        </px-new-table>
      </div>
    </div>
    <!--begin::Body-->
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  props: {
    currentManagerId: {
      type: String,
    },
    archive: {
      type: Boolean,
    },
  },
  data() {
    return {
      loader: false,
      header: [
        {
          key: 'scope_type',
          title: 'Type',
          sort: false,
          class: 'w-40px',
        },
        {
          key: 'address',
          title: 'Project',
          sort: false,
        },
        {
          key: 'city',
          title: 'City',
          sort: false,
        },
        {
          key: 'version',
          title: 'Version',
          sort: true,
        },
        {
          key: 'created_at',
          title: 'Created',
          sort: true,
          class: 'text-nowrap',
        },
        {
          key: 'updated_at',
          title: 'Modified',
          sort: true,
          class: 'text-nowrap',
        },
        {
          key: 'team',
          title: 'Team',
          sort: false,
          class: 'text-nowrap',
        },
        {
          key: 'value',
          title: 'Value',
          sort: true,
          class: 'text-nowrap text-end pe-3',
        },
      ],
      filter: {},
      items: [],
    };
  },
  computed: {
    ...mapGetters({
      isMaster: 'auth/isMaster',
      proposals: 'proposals/proposals',
    }),
  },
  async mounted() {
    this.filter = this.$route.query;

    await this.filtered({ page: 1, state: 'Proposal', current: true });
  },
  methods: {
    ...mapActions({
      index: 'proposals/initProposals',
    }),
    typeFirstLetter(type) {
      switch (type) {
        case 'Swimming Pool':
          return 'P';
        case 'Landscaping':
          return 'L';
        case 'Woodworking':
          return 'W';
        default:
          return 'N';
      }
    },
    managerFullName(name, surname) {
      return `${name} ${surname}`;
    },
    async values() {
      this.items = await this.proposals?.data;
    },
    async filtered(data = {}) {
      const params = {
        fields: 'scope_type,subject,version,created_at,updated_at,users_array,sub_total',
      };
      if (typeof this.archive !== 'undefined') params.archive = this.archive;
      if (!this.hasExtendedRoles) params.designer_ids = this.currentUser.id;

      this.loader = true;

      if (this.currentManagerId && !this.isMaster) params.manager = this.currentManagerId;

      await this.index({ ...data, ...params });
      await this.values();

      this.loader = false;
    },
  },
};
</script>
