<template>
  <div>
    <div class="d-flex mb-6">
      <div class="d-flex align-items-center weather-widget__weather-now">
        <div class="d-flex align-items-center me-4">
          <div class="weather-widget__temp-now me-2">{{ temp > 0 ? '+' : '-' }}{{ temp }}&deg;</div>
          <div class="weather-widget__icon-now">
            <span class="svg-icon svg-icon-5x">
              <inline-svg :src="`/assets/media/icons/weather/${icon}.svg`" />
            </span>
          </div>
        </div>

        <div class="fs-8">
          <div class="weather-widget__desc-now text-capitalize">
            {{ condition }}
          </div>
        </div>
      </div>
    </div>
    <div class="d-flex justify-content-between text-muted">
      <div>
        <i class="bi bi-wind" />
        {{ windSpeed }} m/s, {{ degreeToDirection(windDirection) }}
      </div>
      <div>
        <i class="bi bi-moisture" />
        {{ humidity }}%
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    temp: {
      type: Number,
    },
    condition: {
      type: String,
    },
    icon: {
      type: String,
    },
    windSpeed: {
      type: Number,
    },
    windDirection: {
      type: Number,
    },
    humidity: {
      type: Number,
    },
  },
};
</script>
