<template>
  <div class="card mb-7 mb-mb-0">
    <!--begin::Header-->
    <div class="card-header align-items-center py-2">
      <h4 class="card-title fw-bold">Recent Payments</h4>
    </div>
    <!--end::Header-->

    <!--begin::Body-->
    <div class="card-body pt-3 pb-0">
      <div class="position-relative min-h-300px">
        <px-loader v-if="loader" :is-full-window="false" />
        <px-new-table v-else :header="header" :items="payments" :paginate="false" :has-wrapper="false">
          <template slot="cash" slot-scope="{ item }">
            <i
              :class="[
                'fa fa-genderless fs-2',
                { 'text-danger': !item.attributes.cash },
                { 'text-success': item.attributes.cash },
              ]"
            />
          </template>
          <template slot="project" slot-scope="{ item }">
            {{ item.attributes.proposal_scope.data.attributes.subject.data.attributes.address_line_1 }}
          </template>
          <template slot="city" slot-scope="{ item }">
            {{ item.attributes.proposal_scope.data.attributes.subject.data.attributes.city }}
          </template>
          <template slot="date" slot-scope="{ item }">
            <template v-if="isCurrentYear(item.attributes.date)">
              {{ item.attributes.date | moment('MMM D') }}
            </template>
            <template v-else>
              {{ item.attributes.date | moment('MMM D, YYYY') }}
            </template>
          </template>
          <template slot="amount" slot-scope="{ item }">
            {{ $n(item.attributes.amount, 'currency', 'en-CA') }}
          </template>
        </px-new-table>
      </div>
    </div>
    <!--begin::Body-->
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  props: {
    currentManagerId: {
      type: String,
    },
  },
  data() {
    return {
      loader: true,
      header: [
        {
          key: 'cash',
          title: '',
          sort: false,
          class: 'w-20px pe-0',
        },
        {
          key: 'project',
          title: 'Project',
          sort: false,
        },
        {
          key: 'city',
          title: 'City',
          sort: false,
        },
        {
          key: 'date',
          title: 'Date',
          sort: false,
        },
        {
          key: 'amount',
          title: 'Amount',
          sort: false,
          class: 'text-end',
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      isMaster: 'auth/isMaster',
      payments: 'payments/payments',
    }),
  },
  async mounted() {
    await this.filtered({ current: true });

    this.loader = false;
  },
  methods: {
    ...mapActions({
      actionInitOverall: 'payments/initOverall',
    }),
    async filtered(data = {}) {
      const params = {
        per: 10,
        archive: false,
        fields: 'cash,proposal_scope,date,amount',
      };

      this.loader = true;

      if (this.currentManagerId && !this.isMaster) params.manager = this.currentManagerId;

      await this.actionInitOverall({ ...data, ...params });

      this.loader = false;
    },
  },
};
</script>
