<template>
  <div class="text-center">
    <h6 class="weather-widget__title-other mb-2">{{ day | moment('ddd') }}</h6>
    <div class="weather-widget__icon-other mb-2">
      <span class="svg-icon svg-icon-1">
        <inline-svg :src="`/assets/media/icons/weather/${icon}.svg`" />
      </span>
    </div>
    <div class="weather-widget__temp-other">{{ temp > 0 ? '+' : '-' }}{{ temp }}&deg;</div>
  </div>
</template>

<script>
export default {
  props: {
    day: {
      type: String,
    },
    icon: {
      type: String,
    },
    temp: {
      type: Number,
    },
  },
};
</script>
