var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"card mb-7"},[_c('div',{staticClass:"card-header align-items-center py-2"},[_c('h4',{staticClass:"card-title fw-bold"},[_vm._v("Recent Proposals")]),_c('div',{staticClass:"card-toolbar"},[_c('router-link',{staticClass:"btn btn-sm btn-light btn-active-primary d-flex align-items-center",attrs:{"to":{ name: 'create-proposal' }}},[_c('span',{staticClass:"svg-icon svg-icon-3"},[_c('inline-svg',{attrs:{"src":"/assets/media/icons/duotune/arrows/arr075.svg"}})],1),_vm._v(" New Proposal ")])],1)]),_c('div',{staticClass:"card-body py-3"},[_c('div',{staticClass:"position-relative"},[(_vm.loader)?_c('px-loader',{attrs:{"is-full-window":false}}):_vm._e(),_c('px-new-table',{attrs:{"header":_vm.header,"items":_vm.items,"paginate":false,"has-wrapper":false},on:{"filtered":_vm.filtered},scopedSlots:_vm._u([{key:"scope_type",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-body"},[_c('div',{staticClass:"symbol symbol-35px symbol-circle"},[_c('span',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
                  content: item.attributes.scope_type,
                  delay: { show: 400, hide: 0 },
                }),expression:"{\n                  content: item.attributes.scope_type,\n                  delay: { show: 400, hide: 0 },\n                }"}],class:[
                  'symbol-label text-white fw-semi-bold',
                  { 'bg-primary': item.attributes.scope_type === 'Swimming Pool' },
                  { 'bg-success': item.attributes.scope_type === 'Landscaping' },
                  { 'bg-warning': item.attributes.scope_type === 'Woodworking' },
                  { 'bg-dark': !item.attributes.scope_type } ]},[_vm._v(" "+_vm._s(_vm.typeFirstLetter(item.attributes.scope_type))+" ")])])])]}},{key:"address",fn:function(ref){
                var item = ref.item;
return [_c('router-link',{staticClass:"text-body text-hover-primary",attrs:{"to":{ name: 'proposal-preview', params: { id: item.id } }}},[_vm._v(" "+_vm._s(item.attributes.subject.data.attributes.address_line_1)+" ")])]}},{key:"city",fn:function(ref){
                var item = ref.item;
return [_vm._v(" "+_vm._s(item.attributes.subject.data.attributes.city)+" ")]}},{key:"version",fn:function(ref){
                var item = ref.item;
return [_c('span',{staticClass:"badge badge-light fw-semi-bold fs-10"},[_vm._v("v"+_vm._s(item.attributes.version || '1'))])]}},{key:"created_at",fn:function(ref){
                var item = ref.item;
return [_c('span',{staticClass:"text-body d-block"},[(_vm.isCurrentYear(item.attributes.created_at))?[_vm._v(" "+_vm._s(_vm._f("moment")(item.attributes.created_at,'MMM D'))+" ")]:[_vm._v(" "+_vm._s(_vm._f("moment")(item.attributes.created_at,'MMM D, YYYY'))+" ")]],2)]}},{key:"updated_at",fn:function(ref){
                var item = ref.item;
return [_c('span',{staticClass:"text-body d-block"},[(_vm.isCurrentYear(item.attributes.updated_at))?[_vm._v(" "+_vm._s(_vm._f("moment")(item.attributes.updated_at,'MMM D'))+" ")]:[_vm._v(" "+_vm._s(_vm._f("moment")(item.attributes.updated_at,'MMM D, YYYY'))+" ")]],2),(_vm.compareDates(item.attributes.created_at, item.attributes.updated_at))?_c('span',{staticClass:"text-body d-block"},[_vm._v(" "+_vm._s(_vm._f("moment")(item.attributes.updated_at,'LTS'))+" ")]):_vm._e()]}},{key:"team",fn:function(ref){
                var item = ref.item;
return [_c('div',{staticClass:"symbol-group flex-nowrap"},[_c('span',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
                content: ((item.attributes.subject.data.attributes.manager.data.attributes.first_name) + " " + (item.attributes.subject.data.attributes.manager.data.attributes.last_name)),
                delay: { show: 400, hide: 0 },
              }),expression:"{\n                content: `${item.attributes.subject.data.attributes.manager.data.attributes.first_name} ${item.attributes.subject.data.attributes.manager.data.attributes.last_name}`,\n                delay: { show: 400, hide: 0 },\n              }"}],staticClass:"symbol symbol-35px symbol-circle me-4"},[(item.attributes.subject.data.attributes.manager.data.attributes.avatar)?_c('img',{attrs:{"src":item.attributes.subject.data.attributes.manager.data.attributes.avatar,"alt":((item.attributes.subject.data.attributes.manager.data.attributes.first_name) + " " + (item.attributes.subject.data.attributes.manager.data.attributes.last_name))}}):_c('span',{staticClass:"symbol-label bg-light-primary text-primary fw-semi-bold"},[_vm._v(" "+_vm._s(item.attributes.subject.data.attributes.manager.data.attributes.first_name.charAt(0))+_vm._s(item.attributes.subject.data.attributes.manager.data.attributes.last_name.charAt(0))+" ")])]),(item.attributes.users_array.length)?_vm._l((item.attributes.users_array),function(user,index){return _c('span',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
                  content: user[0],
                  delay: { show: 400, hide: 0 },
                }),expression:"{\n                  content: user[0],\n                  delay: { show: 400, hide: 0 },\n                }"}],key:index,staticClass:"symbol symbol-35px symbol-circle"},[(user[1])?_c('img',{attrs:{"src":user[1],"alt":user[0]}}):_c('span',{staticClass:"symbol-label bg-light-primary text-primary fw-semi-bold"},[_vm._v(" "+_vm._s(_vm.firstLettersOfEachWord(user[0]))+" ")])])}):_vm._e()],2)]}},{key:"value",fn:function(ref){
                var item = ref.item;
return [_c('span',{staticClass:"text-body d-block"},[_vm._v(" "+_vm._s(_vm.$n(item.attributes.sub_total, 'currency', 'en-CA'))+" ")])]}}])})],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }